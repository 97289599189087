// React
import dayjs from "dayjs";
import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import { Tooltip as ReactTooltip } from "react-tooltip";
//Css
import "@/components/Loader/Loader.scss";
import "react-day-picker/dist/style.css";
import "./History.scss";

//Const
import { listHistorySegments } from "@/constants/selects";

//Hooks
import { useAppDispatch, useAppSelector } from "@/hooks";
import {
    getAHAdaptation,
    getAHAdaptationSection,
    getAHAnalysisPlan,
    getAHAttentionOrigin,
    getAHBackground,
    getAHDiagnosticImpression,
    getAHDownloadData,
    getAHLowVision,
    getAHLowVisionSection,
    getAHOphthalmology,
    getAHOphthalmologySection,
    getAHOptometry,
    getAHOptometrySection,
    getAHOrthoptic,
    getAHOrthopticSection,
    getAHPachymetry,
    getAHPio,
    getAHPreconsulting,
    getAHPreconsultingSection,
    getAHPreoperative,
    getAHPreoperativeSection,
    getAHSurgery,
    getAdxDetail,
    getAllImpressionDiagnostic,
} from "./history.actions";

//Icons
import { IconDownload, IconReplaceFilled, IconX } from "@tabler/icons-react";

//Components
import { CustomDayPicker, Select, TextField } from "@/components";
import {
    AttentionAdaptation,
    AttentionAdaptationSection,
    AttentionAdxSection,
    AttentionAnalysisPlan,
    AttentionBackground,
    AttentionDI,
    AttentionHistoryParams,
    AttentionLowVision,
    AttentionLowVisionSection,
    AttentionOphthalmology,
    AttentionOphthalmologySection,
    AttentionOptometry,
    AttentionOptometrySection,
    AttentionOrigin,
    AttentionOrthoptic,
    AttentionOrthopticSection,
    AttentionPachymetry,
    AttentionPio,
    AttentionPreconsulting,
    AttentionPreconsultingSection,
    AttentionPreoperative,
    AttentionPreoperativeSection,
    AttentionSurgery,
    getAllDiagnosticImpression,
} from "@/models/history";
import { IconSearch } from "@tabler/icons-react";
import { DateRange } from "react-day-picker";
import { useDebouncedCallback } from "use-debounce";
import { CardFilterByDates, CardFilterBySections } from "./components";

import { DownloadInfo } from "@/models/historyDetails/download";
import { formatteDocument } from "@/utils";
import { IconRefresh } from "@tabler/icons-react";
import { useLocation } from "react-router-dom";
import ReactToPrint from "react-to-print";
import ExternalHC from "./ExternalHC";
import PioChart from "./PioChart";

interface IHistory {
    completePathHistorySegments?: string;
    setShowHistory?: Dispatch<SetStateAction<boolean>>;
    showClose?: boolean;
    width?: string;
    patientIdProp?: number | null;
    patientName?: string;
    patientAge?: number | string;
    patientIdentification?: number | string;
    defaultHistory?: string;
    allData?: boolean;
    appId?: number;
}

const History = ({
    appId,
    completePathHistorySegments,
    setShowHistory,
    showClose = true,
    width,
    patientIdProp = null,
    patientAge,
    defaultHistory,
    allData,
}: IHistory) => {
    const dispatch = useAppDispatch();
    const location = useLocation();
    const mcfId = useAppSelector((state) => state.patientAttention.patientStatus.mcfId);
    const {patientId, patientIdentification, patientName} = useAppSelector((state) => state.patientAttention.appointmentSelected);
    const accountId = useAppSelector((state) => state.workspace.id);
    const currentSheet = useAppSelector((state) => state.patientAttention.patientStatus.sheet);
    const pdfRef = useRef<HTMLTableElement>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [range, setRange] = useState<DateRange | undefined>();
    const [showCardFilterBySections, setShowCardFilterBySections] = useState<boolean>(false);
    const [showCardFilterByDates, serShowCardFilterByDates] = useState<boolean>(true);
    const [isFirstClick, setIsFirstClick] = useState(true);
    const [attentionHistoryParams, setAttentionHistoryParams] = useState<AttentionHistoryParams>({
        patient_id: patientIdProp ?? patientId,
        prefix: currentSheet,
        label: "",
    });
    const [chartConfig, setChartConfig] = useState<boolean>(false);
    const [downloadData, setDownloadData] = useState<DownloadInfo>({});
    const [impressionDiagnosticRes, setImpressionDiagnosticRes] = useState<getAllDiagnosticImpression[]>([]);
    const [attentionsOptometry, setAttentionsOptometry] = useState<AttentionOptometry[]>([]);
    const [attentionsOptometrySection, setAttentionsOptometrySection] = useState<AttentionOptometrySection>({});
    const [attentionsLowVision, setAttentionsLowVision] = useState<AttentionLowVision[]>([]);
    const [attentionsLowVisionSection, setAttentionsLowVisionSection] = useState<AttentionLowVisionSection>({});
    const [selectedSheet, setSelectedSheet] = useState<{ label: string; value: string }>();

    const [attentionsAdaptation, setAttentionsAdaptation] = useState<AttentionAdaptation[]>([]);
    const [attentionsAdaptationSection, setAttentionsAdaptationSection] = useState<AttentionAdaptationSection>({});
    const [attentionsOphthalmology, setAttentionsOphthalmology] = useState<AttentionOphthalmology[]>([]);
    const [attentionsOphthalmologySection, setAttentionsAdaptatonSection] = useState<AttentionOphthalmologySection>({});
    const [attentionsOrthoptic, setAttentionsOrthoptic] = useState<AttentionOrthoptic[]>([]);
    const [attentionsOrthopticSection, setAttentionsOrthopticSection] = useState<AttentionOrthopticSection>({});
    const [attentionsAttentionOrigin, setAttentionsAttentionOrigin] = useState<AttentionOrigin[]>([]);
    const [attentionsBackground, setAttentionsBackground] = useState<AttentionBackground[]>([]);
    const [attentionsAnalysisPlan, setAttentionsAnalysisPlan] = useState<AttentionAnalysisPlan[]>([]);
    const [attentionsPachymetry, setAttentionsPachymetry] = useState<AttentionPachymetry[]>([]);
    const [attentionDi, setAttentionDI] = useState<AttentionDI[]>([]);
    const [attentionsPreoperative, setAttentionsPreoperative] = useState<AttentionPreoperative[]>([]);
    const [attentionsPreoperativeSection, setAttentionsPreoperativeSection] = useState<AttentionPreoperativeSection>({});
    const [attentionsPreconsulting, setAttentionsPreconsulting] = useState<AttentionPreconsulting[]>([]);
    const [attentionsPreconsultingSection, setAttentionsPreconsultingSection] = useState<AttentionPreconsultingSection>({});
    const [attentionsPio, setAttentionsPio] = useState<AttentionPio[]>([]);
    const [attentionsSurgery, setAttentionsSurgery] = useState<AttentionSurgery[]>([]);
    const [attentionAdx, setAttentionAdx] = useState<AttentionAdxSection[]>([]);
    const currentYear = new Date().getFullYear();

    const [showExternalComponent, setShowExternalComponent] = useState(false);

    useEffect(() => {
        if (defaultHistory) {
            const prefix = listHistorySegments.find((segment) => segment.value === defaultHistory);
            if (prefix) setSelectedSheet(prefix);
        } else {
            const prefix = listHistorySegments.find((segment) => segment.value === currentSheet);
            if (prefix) setSelectedSheet(prefix);
        }
    }, [currentSheet, defaultHistory]);

    useEffect(() => {
        async function fetch() {
            if (mcfId && !location.pathname.includes("surgery")) {
                const response = await dispatch(getAHDownloadData(allData ? null : accountId, mcfId));
                if (response) setDownloadData(response);
            } else {
                const response = await dispatch(getAHDownloadData(accountId, null, appId, patientIdProp ?? undefined));
                if (response) setDownloadData(response);
            }
        }
        fetch();
    }, [dispatch, accountId, mcfId, allData, appId, location.pathname, patientIdProp]);

    useEffect(() => {
        getAllDataDiagnostic();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [attentionHistoryParams.startDate, attentionHistoryParams.endDate]);

    useEffect(() => {
        if (selectedSheet?.value) {
            setAttentionHistoryParams((prev) => ({
                ...prev,
                prefix: selectedSheet?.value,
                label: selectedSheet?.label,
            }));
        }
    }, [selectedSheet?.value, selectedSheet?.label]);

    useEffect(() => {
        async function fetchData() {
            try {
                setIsLoading(true);

                // --------- OPTOMETRY ----------------
                if (attentionHistoryParams && attentionHistoryParams.prefix === "optometry" && !attentionHistoryParams.section) {
                    const data = await dispatch(getAHOptometry(attentionHistoryParams));
                    if (data) {
                        if (data?.length > 0) data[0] = { ...data[0], isOpen: true };
                        setAttentionsOptometry(data);
                    }
                }
                if (attentionHistoryParams && attentionHistoryParams.prefix === "optometry" && attentionHistoryParams.section) {
                    const data = await dispatch(getAHOptometrySection(attentionHistoryParams));
                    if (data && Object.keys(data).length > 0) {
                        setAttentionsOptometrySection({ ...data, correctionOpen: true });
                    } else {
                        setAttentionsOptometrySection({});
                    }
                }

                // ----------- LOW VISION ---------------
                if (attentionHistoryParams && attentionHistoryParams.prefix === "lowVision" && !attentionHistoryParams.section) {
                    const data = await dispatch(getAHLowVision(attentionHistoryParams));
                    if (data) {
                        if (data?.length > 0) data[0] = { ...data[0], isOpen: true };
                        setAttentionsLowVision(data);
                    }
                }
                if (attentionHistoryParams && attentionHistoryParams.prefix === "lowVision" && attentionHistoryParams.section) {
                    const data = await dispatch(getAHLowVisionSection(attentionHistoryParams));
                    if (data && Object.keys(data).length > 0) {
                        setAttentionsLowVisionSection({ ...data, correctionOpen: true });
                    } else {
                        setAttentionsLowVisionSection({});
                    }
                }

                // ------------- ADAPTATION ---------------
                if (attentionHistoryParams && attentionHistoryParams.prefix === "adaptation" && !attentionHistoryParams.section) {
                    const data = await dispatch(getAHAdaptation(attentionHistoryParams));
                    if (data) {
                        if (data?.length > 0) data[0] = { ...data[0], isOpen: true };
                        setAttentionsAdaptation(data);
                    }
                }
                if (attentionHistoryParams && attentionHistoryParams.prefix === "adaptation" && attentionHistoryParams.section) {
                    const data = await dispatch(getAHAdaptationSection(attentionHistoryParams));
                    if (data && Object.keys(data).length > 0) {
                        setAttentionsAdaptationSection({ ...data, contactLensesOpen: true });
                    } else {
                        setAttentionsAdaptationSection({});
                    }
                }

                // ------------- OPHTHALMOLOGY ---------------
                if (attentionHistoryParams && attentionHistoryParams.prefix === "ophthalmology" && !attentionHistoryParams.section) {
                    const data = await dispatch(getAHOphthalmology(attentionHistoryParams));
                    if (data) {
                        if (data.length > 0) data[0] = { ...data[0], isOpen: true };
                        setAttentionsOphthalmology(data);
                    }
                }
                if (attentionHistoryParams && attentionHistoryParams.prefix === "ophthalmology" && attentionHistoryParams.section) {
                    const data = await dispatch(getAHOphthalmologySection(attentionHistoryParams));
                    if (data && Object.keys(data).length > 0) {
                        setAttentionsAdaptatonSection({ ...data, externalOpen: true });
                    } else {
                        setAttentionsAdaptatonSection({});
                    }
                }

                // ------------- ORTHOPTIC ---------------
                if (attentionHistoryParams && attentionHistoryParams.prefix === "orthoptic" && !attentionHistoryParams.section) {
                    const data = await dispatch(getAHOrthoptic(attentionHistoryParams));
                    if (data) {
                        if (data.length > 0) data[0] = { ...data[0], isOpen: true };
                        setAttentionsOrthoptic(data);
                    }
                }
                if (attentionHistoryParams && attentionHistoryParams.prefix === "orthoptic" && attentionHistoryParams.section) {
                    const data = await dispatch(getAHOrthopticSection(attentionHistoryParams));
                    if (data && Object.keys(data).length > 0) {
                        setAttentionsOrthopticSection({ ...data, correctionOpen: true, breadthAccommodationOpen: false });
                    } else {
                        setAttentionsOrthopticSection({});
                    }
                }

                // --------------------- ATTENTION ORIGIN -----------------------
                if (attentionHistoryParams && attentionHistoryParams.prefix === "attentionOrigin" && !attentionHistoryParams.section) {
                    const data = await dispatch(getAHAttentionOrigin(attentionHistoryParams));
                    if (data) {
                        if (data.length > 0) data[0] = { ...data[0], isOpen: true };
                        setAttentionsAttentionOrigin(data);
                    }
                }

                // --------------------- BACKGROUND -----------------------
                if (attentionHistoryParams && attentionHistoryParams.prefix === "background" && !attentionHistoryParams.section) {
                    const data = await dispatch(getAHBackground(attentionHistoryParams));
                    if (data) {
                        setAttentionsBackground(data);
                    }
                }

                // --------------------- ANALYSIS AND PLAN -----------------------
                if (attentionHistoryParams && attentionHistoryParams.prefix === "analysisPlan" && !attentionHistoryParams.section) {
                    const data = await dispatch(getAHAnalysisPlan(attentionHistoryParams));
                    if (data) {
                        if (data.length > 0) data[0] = { ...data[0], isOpen: true };
                        setAttentionsAnalysisPlan(data);
                    }
                }

                // --------------------- PACHYMETRY -----------------------
                if (attentionHistoryParams && attentionHistoryParams.prefix === "pachymetry" && !attentionHistoryParams.section) {
                    const data = await dispatch(getAHPachymetry(attentionHistoryParams));
                    if (data) setAttentionsPachymetry(data);
                }

                // --------------------- DIAGNOSTIC IMPRESSION -----------------------
                if (attentionHistoryParams && attentionHistoryParams.prefix === "diagnosticImpression" && !attentionHistoryParams.section) {
                    const data = await dispatch(getAHDiagnosticImpression(attentionHistoryParams));
                    if (data) {
                        if (data.length > 0) data[0] = { ...data[0], isOpen: true };
                        setAttentionDI(data);
                    }
                }

                // --------------------- PRECONSULTING -----------------------
                if (attentionHistoryParams && attentionHistoryParams.prefix === "preconsulting" && !attentionHistoryParams.section) {
                    const data = await dispatch(getAHPreconsulting(attentionHistoryParams));
                    if (data) {
                        if (data.length > 0) data[0] = { ...data[0], isOpen: true };
                        setAttentionsPreconsulting(data);
                    }
                }
                if (attentionHistoryParams && attentionHistoryParams.prefix === "preconsulting" && attentionHistoryParams.section) {
                    const data = await dispatch(getAHPreconsultingSection(attentionHistoryParams));
                    if (data && Object.keys(data).length > 0) {
                        setAttentionsPreconsultingSection({ ...data, dilatedOpen: true });
                    } else {
                        setAttentionsPreconsultingSection({});
                    }
                }

                // --------------------- PIO -----------------------
                if (attentionHistoryParams && attentionHistoryParams.prefix === "pio" && !attentionHistoryParams.section) {
                    const data = await dispatch(getAHPio(attentionHistoryParams));
                    if (data) {
                        setAttentionsPio(data);
                    }
                }
                // --------------------- SURGERY -----------------------
                if (attentionHistoryParams && attentionHistoryParams.prefix === "surgery" && !attentionHistoryParams.section) {
                    const data = await dispatch(getAHSurgery(attentionHistoryParams));
                    if (data) {
                        if (data.length > 0) data[0] = { ...data[0], isOpen: true };
                        setAttentionsSurgery(data);
                    }
                }
                // ------------- PREOPERATIVE ---------------
                if (attentionHistoryParams && attentionHistoryParams.prefix === "preoperative" && !attentionHistoryParams.section) {
                    const data = await dispatch(getAHPreoperative(attentionHistoryParams));
                    if (data && data.length > 0) {
                        setAttentionsPreoperative(data);
                    } else {
                        setAttentionsPreoperative([]);
                    }
                }
                if (attentionHistoryParams && attentionHistoryParams.prefix === "preoperative" && attentionHistoryParams.section) {
                    const data = await dispatch(getAHPreoperativeSection(attentionHistoryParams));
                    if (data && Object.keys(data).length > 0) {
                        setAttentionsPreoperativeSection(data);
                    } else {
                        setAttentionsPreoperativeSection({});
                    }
                }
                if (attentionHistoryParams && attentionHistoryParams.prefix === "diagnosticAids" && !attentionHistoryParams.section) {
                    const data = await dispatch(getAdxDetail(attentionHistoryParams));
                    if (data) {
                        if (data.length > 0) data[0] = { ...data[0], isOpen: true };
                        setAttentionAdx(data);
                    }
                }
            } finally {
                setIsLoading(false);
            }
        }
        fetchData();
    }, [dispatch, attentionHistoryParams]);

    const onChangeDate = (e?: DateRange) => {
        if (dayjs(e?.from).isSame(e?.to)) {
            setRange(undefined);
            setAttentionHistoryParams({
                ...attentionHistoryParams,
                startDate: undefined,
                endDate: undefined,
            });
            return;
        }
        if (range?.from && range?.to) {
            setRange({
                from: dayjs(e?.from).isBefore(range.from) ? e?.from : e?.to,
            });
            setAttentionHistoryParams({
                ...attentionHistoryParams,
                startDate: dayjs(e?.from).isBefore(range.from) ? dayjs(e?.from).format("YYYY-MM-DD") : dayjs(e?.to).format("YYYY-MM-DD"),
                endDate: undefined,
            });
        } else {
            setRange(e);
            setAttentionHistoryParams({
                ...attentionHistoryParams,
                startDate: dayjs(e?.from).format("YYYY-MM-DD"),
                endDate: dayjs(e?.to).format("YYYY-MM-DD"),
            });
        }
        return range;
    };

    const onSearchIDX = useDebouncedCallback((value: string) => {
        setAttentionHistoryParams({
            ...attentionHistoryParams,
            search: value,
        });
    }, 500);

    const getAllDataDiagnostic = async () => {
        const response = await dispatch(
            getAllImpressionDiagnostic({
                eaccount: accountId,
                patient_id: patientIdProp ?? patientId,
                startDate: attentionHistoryParams.startDate ?? "",
                endDate: attentionHistoryParams.endDate ?? "",
            })
        );
        if (response) {
            setImpressionDiagnosticRes(response.diagnosticImpression);
        }
    };
    const renderDownload = () => {
        return (
            <table
                border={0}
                ref={pdfRef}
                className="w-100"
            >
                <thead>
                    <tr>
                        <td>
                            <div className="print-header">
                                <div className="d-flex align-items-center justify-content-between">
                                    <img
                                        src={downloadData.header?.logo}
                                        alt="cofca_logo"
                                        width={150}
                                        height={50}
                                        style={{ objectFit: "contain" }}
                                    />
                                    <h2 className="text-secondary fw-bold">{downloadData.header?.name_of_the_institution}</h2>
                                    <div></div>
                                    <div></div>
                                </div>
                                <div className="row p-3 py-2 align-items-center bg-light text-muted mt-3">
                                    <div className="col-6">
                                        <div className="row align-items-center">
                                            <div className="col">
                                                <h5 className="fw-bold">Paciente:</h5>
                                                <h5 className="fw-bold">Documento:</h5>
                                            </div>
                                            <div className="col-8">
                                                <h5>{downloadData.header?.patient?.name}</h5>
                                                <h5>{formatteDocument({ value: downloadData.header?.patient?.doc }).format}</h5>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="row align-items-center">
                                            <div className="col-6">
                                                <h5 className="fw-bold nowrap">Fecha de nacimiento:</h5>
                                                <h5 className="fw-bold">Género:</h5>
                                            </div>
                                            <div className="col-6">
                                                <h5>{downloadData.header?.patient?.birthdate}</h5>
                                                <h5>{downloadData.header?.patient?.gender}</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div style={{ height: 10 }}></div>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            <div className="printable">
                                <h3 className="text-primary mt-4">Impresión diagnóstica</h3>
                                <table className="table table-bordered my-2">
                                    <thead>
                                        <tr className="text-secondary">
                                            <th>Fecha</th>
                                            <th className="text-center">CIE-10</th>
                                            <th>Impresión diagnóstica</th>
                                            <th className="text-center">Ojo</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {impressionDiagnosticRes.length > 0 &&
                                            impressionDiagnosticRes.map((item) => (
                                                <tr key={`${item.id}-${item.chlId}`}>
                                                    <td className="align-middle">{item.date}</td>
                                                    <td
                                                        className="align-middle"
                                                        align="center"
                                                    >
                                                        {item.cie10}
                                                    </td>
                                                    <td style={{ whiteSpace: "pre-line" }}>
                                                        {item.descriptionAmp && (
                                                            <>
                                                                <span>
                                                                    <b>AMP / </b>
                                                                    {item.descriptionAmp}
                                                                </span>{" "}
                                                                <br />
                                                            </>
                                                        )}
                                                        <span>
                                                            <b>{item.cie10} / </b>
                                                            {item.descriptionCie10}
                                                        </span>
                                                    </td>
                                                    <td
                                                        className="align-middle"
                                                        align="center"
                                                    >
                                                        {item.eye}
                                                    </td>
                                                </tr>
                                            ))}
                                    </tbody>
                                </table>
                            </div>
                        </td>
                    </tr>
                </tbody>

                <tfoot>
                    <tr>
                        <td>
                            <div style={{ height: 25 }}></div>
                            <div
                                className="align-bottom text-end text-muted position-fixed bottom-0 w-100"
                                style={{ marginBottom: 0 }}
                            >
                                <h6 className="d-inline text-end mx-2">
                                    <span className="fw-bold">Teléfono: </span>
                                    {downloadData.footer?.appointment_phone}
                                </h6>
                                <h6 className="d-inline text-end mx-2">
                                    <span className="fw-bold">Email: </span>
                                    {downloadData.footer?.appointment_email}
                                </h6>
                                <h6 className="d-inline text-end mx-2">
                                    <span className="fw-bold">Dirección: </span>
                                    {downloadData.footer?.address}
                                </h6>
                            </div>
                        </td>
                    </tr>
                </tfoot>
            </table>
        );
    };

    return showExternalComponent ? (
        <ExternalHC
            width={width ?? "30%"}
            setShowExternalComponent={setShowExternalComponent}
            patientIdProp={patientIdProp ?? patientId}
            patientIdentification={patientIdentification}
            patientName={patientName}
        />
    ) : (
        <div
            className="history"
            style={{ width: width ?? "30%" }}
        >
            <div className="d-flex gap-2">
                {showClose && (
                    <div
                        className="pointer"
                        onClick={() => setShowHistory && setShowHistory(false)}
                    >
                        {location.pathname.includes("DiagnosticAids") ? (
                            <>
                                <ReactTooltip
                                    anchorId="replace"
                                    place="bottom"
                                    content="Ver PDF"
                                    style={{ width: "100px", textAlign: "center", opacity: "0.8", zIndex: 50 }}
                                />
                                <IconRefresh
                                    className="text-black-50"
                                    id="replace"
                                />
                            </>
                        ) : (
                            <IconX />
                        )}
                    </div>
                )}
                <div className=" d-flex">
                    <div
                        onClick={() => setShowExternalComponent(true)}
                        className="pointer"
                    >
                        <>
                            <ReactTooltip
                                anchorId="sis"
                                place="bottom"
                                content="Sistemas externos"
                                style={{ width: "100px", textAlign: "center", opacity: "0.8", zIndex: 50 }}
                            />
                            <IconReplaceFilled
                                className="text-black-50"
                                id="sis"
                            />
                        </>
                    </div>
                </div>
            </div>
            <div className="text-center">
                <div className="fs-3 text-secondary fw-bold mb-1">Historial de atención</div>
                <div className="fs-5 textGray100">¿Qué deseas ver del paciente?</div>
            </div>

            <div className="mt-4">
                <Select
                    className="textGray100"
                    isSearchable
                    showDefaultOption={false}
                    options={listHistorySegments}
                    style={{ width: "100%" }}
                    value={selectedSheet}
                    onChange={({ option }) => {
                        setAttentionHistoryParams({
                            ...attentionHistoryParams,
                            prefix: option.value,
                            label: option.label,
                            search: undefined,
                        });
                        setSelectedSheet(option);
                    }}
                />
            </div>

            <div className="d-flex gap-2 align-items-center justify-content-between mt-4">
                {attentionHistoryParams.prefix === "diagnosticImpression" && (
                    <div className="d-flex">
                        <ReactToPrint
                            trigger={() => {
                                return (
                                    <div className=" pointer textGray100">
                                        <IconDownload />
                                    </div>
                                );
                            }}
                            content={() => pdfRef.current}
                        />
                    </div>
                )}
                {attentionHistoryParams.prefix === "pio" && attentionsPio.length ? (
                    <div
                        className="text-secondary fw-bold pointer"
                        onClick={() => setChartConfig(true)}
                    >
                        Ver gráfica
                    </div>
                ) : (
                    ""
                )}

                {!(
                    attentionHistoryParams.prefix === "background" ||
                    attentionHistoryParams.prefix === "attentionOrigin" ||
                    attentionHistoryParams.prefix === "analysisPlan" ||
                    attentionHistoryParams.prefix === "diagnosticImpression" ||
                    attentionHistoryParams.prefix === "diagnosticAids" ||
                    attentionHistoryParams.prefix === "pio"
                ) ? (
                        <div className="form-check form-switch d-flex align-items-center justify-content-center p-0">
                            <small className="form-check-label textGray100 me-2">Mostrar por sección</small>
                            <input
                                checked={attentionHistoryParams.section === 1}
                                className="form-check-input m-0"
                                type="checkbox"
                                role="switch"
                                style={{ minWidth: 25, minHeight: 15 }}
                                onChange={({ target }) => {
                                    setShowCardFilterBySections(target.checked);
                                    serShowCardFilterByDates(false);
                                    setAttentionHistoryParams({
                                        ...attentionHistoryParams,
                                        startDate: undefined,
                                        endDate: undefined,
                                        section: target.checked ? 1 : 0,
                                    });
                                    setRange(undefined);
                                }}
                            />
                        </div>
                    ) : (
                        <div></div>
                    )}
                <div className="d-flex align-items-center">
                    {attentionHistoryParams.startDate || attentionHistoryParams.endDate ? (
                        <small className="badge-wrapper badge-wrapper__primary fs-">
                            {dayjs(attentionHistoryParams.startDate).format("DD/MM/YYYY")}
                            {attentionHistoryParams.startDate && attentionHistoryParams.endDate ? " al " : ""}
                            {attentionHistoryParams.endDate && dayjs(attentionHistoryParams.endDate).format("DD/MM/YYYY")}
                        </small>
                    ) : (
                        ""
                    )}
                    <CustomDayPicker
                        dayPickerProps={{
                            mode: "range",
                            selected: range,
                            onSelect: (range) => {
                                if (!range) {
                                    setRange(undefined);
                                    setAttentionHistoryParams({
                                        ...attentionHistoryParams,
                                        startDate: undefined,
                                        endDate: undefined,
                                    });
                                    setIsFirstClick(true);
                                    return;
                                }

                                const { from, to } = range;

                                if (isFirstClick || !to) {
                                    setRange({ from, to: from });
                                    setAttentionHistoryParams({
                                        ...attentionHistoryParams,
                                        startDate: dayjs(from).format("YYYY-MM-DD"),
                                        endDate: dayjs(from).format("YYYY-MM-DD"),
                                    });
                                    setIsFirstClick(false);
                                } else {
                                    setRange({ from, to });
                                    setAttentionHistoryParams({
                                        ...attentionHistoryParams,
                                        startDate: dayjs(from).format("YYYY-MM-DD"),
                                        endDate: dayjs(to).format("YYYY-MM-DD"),
                                    });
                                    setIsFirstClick(true);
                                }
                            },
                            className: "my-rdp dropdown-item p-0",
                            captionLayout: "dropdown-buttons",
                            fromYear: 2010,
                            toYear: currentYear,
                        }}
                    />
                </div>
            </div>

            {attentionHistoryParams.prefix === "diagnosticImpression" ? (
                <TextField
                    className="mt-3"
                    placeholder="Buscar IDX..."
                    endIcon={<IconSearch />}
                    onChange={({ target }) => onSearchIDX(target.value)}
                />
            ) : (
                ""
            )}

            {isLoading ? (
                <div className="big-loader align-self-center mt-5"></div>
            ) : (
                <>
                    {showCardFilterBySections && (
                        <CardFilterBySections
                            sectionsOptometry={attentionsOptometrySection}
                            sectionsLowVision={attentionsLowVisionSection}
                            sectionsAdaptation={attentionsAdaptationSection}
                            sectionsOphthalmology={attentionsOphthalmologySection}
                            sectionsOrthoptic={attentionsOrthopticSection}
                            sectionPreoperative={attentionsPreoperativeSection}
                            sectionsPreconsulting={attentionsPreconsultingSection}
                            sheet={attentionHistoryParams.prefix}
                            patientIdProp={attentionHistoryParams.patient_id}
                            patientName={patientName}
                            patientAge={patientAge}
                            allData={allData}
                            patientIdentification={patientIdentification}
                        />
                    )}

                    {(showCardFilterByDates || !showCardFilterBySections) && (
                        <CardFilterByDates
                            allData={allData}
                            attentionsOptometry={attentionsOptometry}
                            attentionsLowVision={attentionsLowVision}
                            attentionsAdaptation={attentionsAdaptation}
                            attentionsOphthalmology={attentionsOphthalmology}
                            attentionsOrthoptic={attentionsOrthoptic}
                            attentionOrigin={attentionsAttentionOrigin}
                            attentionBackground={attentionsBackground}
                            attentionAnalysisPlan={attentionsAnalysisPlan}
                            attentionPachymetry={attentionsPachymetry}
                            attentionDI={attentionDi}
                            attentionPreoperative={attentionsPreoperative}
                            attentionPreconsulting={attentionsPreconsulting}
                            attentionAdx={attentionAdx}
                            attentionPio={attentionsPio}
                            attentionSurgery={attentionsSurgery}
                            sheet={attentionHistoryParams.prefix}
                            sheetLabel={attentionHistoryParams.label}
                            patientIdProp={attentionHistoryParams.patient_id}
                            patientName={patientName}
                            patientAge={patientAge}
                            patientIdentification={patientIdentification}
                        />
                    )}
                </>
            )}

            <PioChart
                fullScreen
                isOpen={chartConfig}
                onClose={() => {
                    setChartConfig(false);
                }}
                data={attentionsPio}
                attentionHistoryParams={attentionHistoryParams}
                onChangeDate={onChangeDate}
                range={range}
            />

            <div style={{ display: "none" }}>{renderDownload()}</div>
        </div>
    );
};

export default History;
