import Swal from "sweetalert2";

export function setHeaders() {
    const token = localStorage.getItem("x_token");
    const headers = { Authorization: "" };

    if (token) {
        headers["Authorization"] = token;
    }

    return headers;
}

export function fireErrorAlert(errorMessage: string, title = "Error", onConfirm?: () => void) {
    Swal.fire({
        title: `<span class="text-primary fw-bold fs-3">${title}</span>`,
        text: errorMessage,
        icon: "error",
        confirmButtonColor: "#00B4CC",
        confirmButtonText: "Aceptar",
        customClass: {
            confirmButton: "button button-primary shadow-none",
            cancelButton: "button button-outline shadow-none",
        },
    }).then((result) => {
        if (result.isConfirmed) {
            onConfirm?.();
        }
    });
}

export function fireSuccessAlert(successMessage: string, title = "Proceso exitoso", onConfirm?: () => void) {
    Swal.fire({
        title: `<span class="text-primary fw-bold fs-3">${title}</span>`,
        text: successMessage,
        icon: "success",
        confirmButtonColor: "#00B4CC",
        confirmButtonText: "Aceptar",
        customClass: {
            confirmButton: "button button-primary shadow-none",
            cancelButton: "button button-outline shadow-none",
        },
    }).then((result) => {
        if (result.isConfirmed) onConfirm?.();
    });
}

export function fireInfoAlert(infoMessage: string, title = "Información", onConfirm?: () => void) {
    Swal.fire({
        title: `<span class="text-primary fw-bold fs-3">${title}</span>`,
        text: infoMessage,
        icon: "info",
        confirmButtonColor: "#00B4CC",
        confirmButtonText: "Aceptar",
        customClass: {
            confirmButton: "button button-primary shadow-none",
            cancelButton: "button button-outline shadow-none",
        },
    }).then((result) => {
        if (result.isConfirmed) onConfirm?.();
    });
}

export function fireWarningAlert(warnMessage: string, title = "Advertencia", onConfirm?: () => void) {
    Swal.fire({
        title: `<span class="text-primary fw-bold fs-3">${title}</span>`,
        text: warnMessage,
        icon: "warning",
        confirmButtonColor: "#00B4CC",
        confirmButtonText: "Aceptar",
        customClass: {
            confirmButton: "button button-primary shadow-none",
            cancelButton: "button button-outline shadow-none",
        },
    }).then((result) => {
        if (result.isConfirmed) {
            onConfirm?.();
        }
    });
}

export function fireCautionAlert(text: string, title?: string, onConfirm?: () => void) {
    Swal.fire({
        icon: "warning",
        title: `<span class='text-primary fw-bold fs-3'>${title || "¿Está seguro?"}</span>`,
        text: text,
        confirmButtonColor: "#00B4CC",
        showCancelButton: true,
        confirmButtonText: "Sí, continuar",
        cancelButtonText: "Cancelar",
        customClass: {
            confirmButton: "button button-primary shadow-none",
            cancelButton: "button button-outline shadow-none",
        },
    }).then((result) => {
        if (result.isConfirmed) {
            onConfirm?.();
        }
    });
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function formatHistoryDetails(data: any): any {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const d: any = {};

    if (typeof data === "object" && data !== null) {
        for (const key in data) {
            const r = formatHistoryDetails(data[key]);

            if (r !== null && (r as string) !== "") {
                if (Array.isArray(r) && r.length === 0) {
                    continue;
                }
                d[key] = r;
            }
        }
    } else {
        if (data !== null && data !== "") {
            return data;
        } else {
            return null;
        }
    }

    return Object.keys(d).length > 0 ? d : null;
}

export function correctionValue(numero: number): number | string {
    if (!(Number(numero) >= 445 && Number(numero) <= 645) || numero === 0) {
        return "";
    }

    const datos = [
        { espesor: 445, correccion: 7 },
        { espesor: 455, correccion: 6 },
        { espesor: 465, correccion: 6 },
        { espesor: 475, correccion: 5 },
        { espesor: 485, correccion: 4 },
        { espesor: 495, correccion: 4 },
        { espesor: 505, correccion: 3 },
        { espesor: 515, correccion: 2 },
        { espesor: 525, correccion: 1 },
        { espesor: 535, correccion: 1 },
        { espesor: 545, correccion: 0 },
        { espesor: 555, correccion: -1 },
        { espesor: 565, correccion: -1 },
        { espesor: 575, correccion: -2 },
        { espesor: 585, correccion: -3 },
        { espesor: 595, correccion: -4 },
        { espesor: 605, correccion: -4 },
        { espesor: 615, correccion: -5 },
        { espesor: 625, correccion: -6 },
        { espesor: 635, correccion: -6 },
        { espesor: 645, correccion: -7 },
    ];

    const espesorArray: number[] = [
        445, 455, 465, 475, 485, 495, 505, 515, 525, 535, 545, 555, 565, 575, 585, 595, 605, 615, 625, 635, 645,
    ];

    let cercano = espesorArray[0];

    for (let i = 1; i < espesorArray.length; i++) {
        if (Math.abs(espesorArray[i] - numero) <= Math.abs(cercano - numero)) {
            cercano = espesorArray[i];
        }
    }

    const findCorrection: number | string = datos.find((x) => x.espesor === cercano)?.correccion ?? "";

    return Number(findCorrection) ?? "";
}

export function parseSearchParams<T>(queryString: string): T {
    const params = new URLSearchParams(queryString);
    const parsed: Record<string, string> = {};

    for (const [key, value] of params) {
        parsed[key] = value;
    }

    return parsed as T;
}

export const hasData = (variable: unknown): boolean => {
    let isValid = false;

    if (typeof variable === "boolean") {
        isValid = !!variable;
    }

    if (typeof variable === "string") {
        isValid = variable.trim() !== "";
    }

    if (typeof variable === "number") {
        isValid = variable > 0;
    }

    if (typeof variable === "undefined") {
        isValid = false;
    }

    if (typeof variable === "object") {
        isValid = Object.keys(variable || {}).length > 0;
    }

    if (variable === null) {
        isValid = false;
    }

    return isValid;
};

export const downloadFileAddName = (file: string, name: string) => {
    const treatPdf = file.replace(/^b'/gm, "").replace(/=*'$/gm, "");
    const linkSource = `data:application/pdf;base64,${treatPdf}`;
    const downloadLink = document.createElement("a");
    const fileName = name.length > 0 ? `${name}.pdf` : "vct_illustration.pdf";
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
};
export const downloadFileURLAddName = async (file: string, name: string) => { 

    const blob = await fetch(file).then((r) => r.blob());
    const f = new FileReader();
    f.readAsDataURL(blob);
    f.onload = (d) => {
        const uri = d?.target?.result as string;
        const link = document.createElement("a"); const url = URL.createObjectURL(blob); 
        link.download = `${name}.pdf`;
        link.href = uri;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
    };
};
