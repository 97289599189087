import { Button, Modal, Select, TextField } from "@/components";
import PdfViewer from "@/components/pdf/PDFviewer";
import { axiosInstance } from "@/config/axios";
import { useAppDispatch, useAppSelector } from "@/hooks";
import { Params } from "@/models/historyDetails/download";
import { setHeaders } from "@/utils";
import { IconReplaceFilled, IconSearch } from "@tabler/icons-react";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { getExternalOptions } from "./history.actions";

interface IHistory {
    completePathHistorySegments?: string;
    setShowExternalComponent: Dispatch<SetStateAction<boolean>>;
    showClose?: boolean;
    width?: string;
    patientIdProp?: number | null;
    patientName?: string;
    patientAge?: number | string;
    patientIdentification?: number | string;
    defaultHistory?: string;
    allData?: boolean;
    appId?: number;
}

const ExternalHC = ({ appId, completePathHistorySegments, setShowExternalComponent, showClose = true, width, patientIdProp = null, patientName, patientAge, patientIdentification, defaultHistory, allData }: IHistory) => {
    const eaccount = useAppSelector(state => state.workspace.id);

    const dispatch = useAppDispatch();
    const [externalOptions, setExternalOptions] = useState<
    {label: string;
        value: number; 
        extra:{
            params: Params;
            search: number;
            api: string;
            httpVerb: string;
            } 
        }[]
    >([]);
    const [selectedOption, setSelectedOption] = useState<{
        params: Params;
        search: number;
        api: string;
        httpVerb: string;
        } | null >( null
        );

    const [showSearch, setShowSearch] = useState<{show: boolean, searchValue: string}>({
        show: false,
        searchValue: ""
    });
    const [downloadButton, setDownloadButton] = useState<{show: boolean, data: string}>({
        show: false,
        data: ""
    });
    const [openVisualizer, setOpenVisualizer] = useState(false);
    const [listPatient, setListPatient] = useState<{label: string, value: number}[]>([]);
    useEffect(() => {
        async function fetch() {
            const response = await dispatch(getExternalOptions({eaccount}));
            if (response) {
                const options = response.map(option => (
                    {label: option.name, 
                        value: option.id, 
                        extra:{
                            params: option.params, 
                            search: option.search, 
                            api: option.api, 
                            httpVerb: option.httpVerb}
                    })
                );
                if(options){
                    setExternalOptions(options);
                }
            }
                
        }
        fetch();

    }, [dispatch, eaccount]);
    const [patientSelected, setPatientSelected] = useState<{name: string; id: null | number}>({
        name: "",
        id: null,
    });
    const [isLoading, setIsLoading] = useState<boolean>(false); 

    const handleOptionExternal = async ({params, search, api,} : {
        params: Params;
        search: number;
        api: string;
        } ) =>{
        setShowSearch((state)=>({...state, show: Boolean(search)}));
        if(search === 0){
            setIsLoading(true); 
            const headers = setHeaders();
            const paramsData = {[params.idPatient]: patientIdProp, 
                filename: `HC ESCANEADA - ${patientIdentification} - ${patientName}`, 
            }; 
            const axiosParams = { headers, params: paramsData };
            const { data: serviceData } = await axiosInstance.get<any>(
                api, axiosParams
            );
            if (serviceData.success){
                setDownloadButton({show: true, data: serviceData.results.key}); 

            }
            setIsLoading(false); 
            setShowSearch({show: false, searchValue: ""});
        }  
    };

    const searchPatient = async (id?: number)=>{
        if(selectedOption){ 
            setIsLoading(true); 

            const headers = setHeaders();
            let params;
            if(id){
                params = {[selectedOption.params.idPatient]: id}; 
            }else{
                params = {[selectedOption.params.findPatient]: showSearch.searchValue}; 
            }
            const axiosParams = { headers, params };
            const { data: serviceData } = await axiosInstance.get<any>(
                `${selectedOption?.api}`, axiosParams
            );
            if (serviceData){
                if(serviceData.success){
                    setPatientSelected({
                        name: "",
                        id: null,
                    });
                    if(id){
                        setDownloadButton({show: true, data: serviceData.results.key});
                        setShowSearch({show: false, searchValue: ""});
                        setListPatient([]); 

                    }else{
                        const options = serviceData.results.map((res: any) => ({label: `${res.firstName} ${res.lastName}`, value: res.id}));
                        setListPatient(options); 
                    } 
                } 
                setIsLoading(false); 
            }
        }
    };


    const showFullScreenModal = () => {
        const treatPdf = downloadButton.data.replace(/^b'/gm, "").replace(/=*'$/gm, ""); 
        return (
            <Modal
                isOpen={openVisualizer}
                onClose={() => setOpenVisualizer(false)}
                fullScreen
                className="d-flex justify-content-center "
            >
                <div className="w-100 h-100 py-5">
                    <PdfViewer
                        pdfUrl={treatPdf}
                    />
   
                </div>
            </Modal>
        );
    };

    return (
        <div className="history"
            style={{ width: width ?? "30%" }}
        >
            {showFullScreenModal()}
            <div className="d-flex"> 
                <div onClick={()=>setShowExternalComponent(false)} className="pointer">
                    <IconReplaceFilled className="text-black-50" id="replace" /> 
                </div>
            </div>


            <div className="py-4">
                <div className="py-3 cardSearch">
                    <h4 className="fw-bold text-primary p-2 text-center">Seleccione módulo externo </h4>
                    <Select
                        isCleanable
                        placeholder="Seleccionar..."
                        isSearchable
                        options={externalOptions}
                        onChange={({ option }) => {
                            setListPatient([]);
                            setPatientSelected({
                                name: "",
                                id: null,
                            }); 
                            setSelectedOption(option.extra);
                            setDownloadButton({show: false, data: ""});
                            setShowSearch({show: false, searchValue: ""});
                            handleOptionExternal(option.extra);
                        }}
                        name="evolutionTimeUnitId"

                    />
         
                </div>
            </div>

            {isLoading ? 
                <div className="big-loader align-self-center mt-5"></div> 
                :
                <> 
                    {(showSearch.show &&  patientSelected.id === null) &&
                    <div className="py-4 gap-2 text-center">
                        <h5 className="fw-bold text-primary p-2 text-center">Busque y seleccione un paciente </h5>
                        <div className="d-flex w-100">
                            <TextField
                                className="w-100"
                                variant="filled"
                                placeholder="Escribe aquí para buscar..."
                                onChange={({ target }) => 
                                    setShowSearch((state)=> ({...state, searchValue: target.value}))
                                }
                                onKeyDown={e=>{
                                    if (e.key === "Enter") {
                                        searchPatient();
                                    }  
                                }}
                                endIcon={
                                    <div onClick={()=>searchPatient()}
                                        className="pointer"
                                    >
                                        <IconSearch /> 
                                    </div> 
                                }
                            /> 
                        </div> 
                    </div> 
                    }
    
                    <div style={{maxHeight: "500px", overflowY: "auto" }}>
    
                        {patientSelected.name === "" &&  listPatient.length > 0 && 
                            listPatient.map((patient) => {
                                return (<div className="w-100">
                                    <div className="pointer"
                                        onClick={()=>{
                                            searchPatient(patient.value); 
                                            setPatientSelected({ name: patient.label, id: patient.value});
                                        }}
                                    >
                                        {patient.label} 
                                    </div>
                                    <hr/>
                                </div>);
                            })
                        }
                    </div>
    
                    {downloadButton.show && 
                    <div className="d-flex justify-content-center py-4">
                        <Button
                            variant="primary"
                            onClick={()=>{
                                setOpenVisualizer(true);
                                // downloadFileAddName(downloadButton.data, "HCX");
                            }}
                        >Visualizar HCX</Button>
                    </div>
                    }
                </>

            }

        </div>  );
};

export default ExternalHC;
